.nav-dock {
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    align-items: flex-end;
    height: 58px;
    width: 100%;
    display: flex;
    padding: 10px;
    padding-bottom: 6px;
    gap: 12px;
    background-color: rgba(0, 0, 0, 0.92);
    will-change: contents;
    /* box-sizing: content-box; */
    transition: 0.3s;
    width: 100%;
  }
  
  .nav-dock:hover {
    top: 0px;
  }
  

  .hideMenuNav {
    display: none;
  }
  .showMenuNav {
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: white;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }