@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      /* Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
        display: none;
      }
  
      .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    }
}

@font-face {
  font-family: "Poppins light";
  src: url("./assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins regular";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins medium";
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins bold";
  src: url("./assets/fonts/Poppins-Bold.ttf");
}
